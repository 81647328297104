import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 36
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
  });


  const BillTo = ({invoice}) => (
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Bill To:</Text>
        <Text>{invoice?.user?.full_name}</Text>
        <Text>{invoice?.shipping_address?.full_address}, {invoice?.shipping_address?.city}</Text>
        <Text>{invoice?.user?.phone_numbers && invoice?.user?.phone_numbers[0]}{invoice?.shipping_address?.phone_number && `/${invoice?.shipping_address?.phone_number}`}</Text>
        <Text>{invoice?.user?.email_address}</Text>
    </View>
  );
  
  export default BillTo