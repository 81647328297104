import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import useBrand from '../../../utils/utilities/hooks/useBrand';
import TablePagination from "../../../components/TablePagination";
// components
let timer = null
export default function BrandData() {
  const navigate = useNavigate()
  let { client } = useBrand()

  const [page, setPage] = useState(1);
  const [limit, _] = useState(10);

  const {
    data: brandData,
    isLoading
  } = useQuery({
    queryKey: ["brands-data", page, limit],
    queryFn: async () => {
      let result = await client.getAllBrands({ page, limit})
      return result;
    },
    keepPreviousData: true
  });

 
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Marques
              </h3>
            </div>
           
            <div className="relative w-full max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  navigate('/brands/form')
                }}
              >
                Créer
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}

          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Libellé
                </th>
                
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                    Actions
                </th>
              </tr>
            </thead>
            <tbody>

              {isLoading &&
                <tr>
                  <td colSpan={6}>
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        padding: 24,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      ><span style={{color: '#263238'}}>Chargement des données ... </span>
                        
                      </div>
                  </td>
                </tr>
              }

              
              {
                brandData?.results?.length ?<>
                {brandData?.results?.map((u, index) => {
                return <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  <div className="w-full flex items-center gap-3"  >
                    {u.name}
                  </div>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="relative w-full max-w-full flex-grow flex-1 text-right">
                        <button
                          onClick={() => {
                            navigate(`/brands/form/${u._id}`)
                          }}
                            className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            <i className="fas fa-solid fa-highlighter"></i>
                        </button>
                    </div>
                </td>

                
              </tr>
              })}
                </> : <tr>
                <td colSpan={6}>
                  
                    <div className="font-bold" style={{padding: "15px 0px", display: 'flex', justifyContent: 'center', alignItems: "center", color: '#263238'}}>Aucune données</div>
                  </td></tr>
              }
              
            </tbody>
          </table>

          {brandData?.results?.length ?  brandData?.results && (
                <TablePagination
                  data={brandData}
                  limit={limit}
                  currentPage={page}
                  setCurrentPage={setPage}
                />
              ): <></>}


        </div>
      </div>
    </>
  );
}