import request from '../../../api'
import { Options } from '../../../api/DataSchemas'
import { PaginationOption } from '../../GlobalDataSchemas'
import { CreateRequest } from '../utils/DataSchemas'

function getPaginationQuery(paginationOption: PaginationOption) {
    let query = `?page=${paginationOption.page}&limit=${paginationOption.limit}`

    if (typeof paginationOption.search !== "undefined") {
      query += `&search=${paginationOption.search}`
    }

    if (typeof paginationOption.status !== "undefined") {
      query += `&status=${paginationOption.status}`
    }

    if (typeof paginationOption.not_status !== "undefined") {
      query += `&not_status=${paginationOption.not_status}`
    }
  
    return query
  }

export default class Service {
    options: Options

    constructor(options: Options) {
        this.options = options
    }
    async createOrder(data: CreateRequest) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
    
      return request('POST', `/order`, requestOptions)
    }

    
    async getAdminOrders(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/order/admin${query}`, this.options)
    }

    async getAdminOrdersDeliveries(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/order/deliveries${query}`, this.options)
    }

    async getReturns(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/order/returns${query}`, this.options)
    }

    
    async getUserOrders(email_address: string, paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/order/user/${email_address}${query}`, this.options)
    }

    async getDeliverOrders(id: string, paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/order/delivery/${id}${query}`, this.options)
    }

    async getOrderById(id: string) {
      return request('GET', `/order/detailed/${id}`, this.options)
    }

    async assignOrder(id: string, assigned_to: any) {
      const requestOptions: Options = {
        ...this.options,
        data: {assigned_to}
      }
    
      return request('PUT', `/order/assign/${id}`, requestOptions)
    }

    async updateUserOrderStatus(id: string, status: string) {
      const requestOptions: Options = {
        ...this.options,
        data: {status}
      }
    
      return request('PUT', `/order/user/status/${id}`, requestOptions)
    }

    async updateAdminOrderStatus(id: string, status: string, failed_delivery_note: string) {
      const requestOptions: Options = {
        ...this.options,
        data: {status, failed_delivery_note}
      }
    
      return request('PUT', `/order/admin/status/${id}`, requestOptions)
    }

    async refunRequest(id: string, status: string) {
      const requestOptions: Options = {
        ...this.options,
        data: {status}
      }
    
      return request('PUT', `/order/refund-request/${id}`, requestOptions)
    }

    async refunProcess(id: string, status: string, not_refunded_note: string) {
      const requestOptions: Options = {
        ...this.options,
        data: {status, not_refunded_note}
      }
    
      return request('PUT', `/order/refund-process/${id}`, requestOptions)
    }
  
}
