import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import BrandForm from "./BrandForm";
import BrandData from "./Brands";

// components

export default function Brands() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path={''} element={<BrandData />} />
        <Route path={'form/*'} element={<BrandForm />} />
        <Route path={'form/:id'} element={<BrandForm />} />
    </Routes>
  );
}