import { AuthContext, AuthStatus } from '../../context/auth';
import React, { useContext, useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Login() {
  const [email_address, setEmailaddress] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('')
  const { signIn, authStatus } = useContext(AuthContext)
  const navigate = useNavigate()
  const { state } = useLocation();
  const loginRouteState = state
  useEffect(() => {
    if (authStatus === AuthStatus.SignedIn) {
      if (loginRouteState?.pathname) {
        navigate(loginRouteState.pathname)
      } else {
        navigate(`/`)
      }
    }
  }, [authStatus])

  const submit = async (e) => {
    e.preventDefault()
    setLoading(true)
    await signIn({email_address, password})
      .then(() => {
        setLoading(false)
      })
      .catch((err) => {
          console.log('error', err)
          setError(err?.message)
          toast.error(err?.message , {position: 'bottom-center'})
          setLoading(false)
      })
  }
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Se connecter
                  </h6>
                </div>
                
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                
                <form autoComplete='off'>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Adresse email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                      placeholder="Email"

                      value={email_address}

                      onChange={(e) => {
                        setEmailaddress(e.target.value)
                      }}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Mot de passe
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={password}

                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      disabled={loading}
                      onClick={submit}
                    >
                      
                      {
                        loading ?
                          <>Loading...</>
                          :
                          <>
                           Se connecter
                          </>
                      }
                    </button>
                  </div>
                </form>

                {error && <div className="w-full flex justify-center mt-3">
                        <div
                          className='w-full  p-2 text-center'
                          style={{
                            background: 'rgba(165, 23, 59, 0.1)',
                            borderRadius: 4,
                            fontWeight: 700,
                            fontSize: 16,
                            color: '#A5173B'
                          }}
                        >
                          {error}
                        </div>
                      </div>}
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <Link
                  to="/pass-forgot"
                  className="text-blueGray-200"
                >
                  <small>Mot de passe oublié?</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
