import { Image } from "../../GlobalDataSchemas"

export enum POSITION {
  firts_bann_right = "firts_bann_right",
  first_bann_left = "first_bann_left",
  full_bann = "full_bann",
  stock_1 = "stock_1",
  stock_2 = "stock_2",
  stock_3 = "stock_3",
  stock_4 = "stock_4",
}
export interface CreateRequest {
  link: string
  position: POSITION
  image: Blob
  new_image?: Blob
}

export interface Banner {
  _id: string
  link: string
  position: string
  image: Image
  created_at: Date
  updated_at: Date
  created_by: string
  updated_by: string
}
