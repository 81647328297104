import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import useOrder from '../../../utils/utilities/hooks/useOrder';
// components
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router";
import { Riple, TrophySpin } from "react-loading-indicators";
import { moneyFormating } from "../../../utils/utilities/constants";
import moment from "moment";
import useUser from "../../../utils/utilities/hooks/userUser";

export default function ReturnVieew() {
    const navigate =  useNavigate()
    const [status, setStatus] = useState(0);
   
    const [currentData, setCurrentData] = useState(null);
    const [not_refunded_note, setnot_refunded_note] = useState('');
    const {id} = useParams()
    
    let { client } = useOrder()
    useEffect(() => {
      if(currentData) {
        setStatus(currentData?.status)
        setnot_refunded_note(currentData?.not_refunded_note)
      }
      
    }, [currentData])


    useQuery({
      queryKey: ['return-data', id],
      queryFn: async () => {
          if(id) {
            let result = await client.getOrderById(id)
            setCurrentData(result)
          }
      }
    })
    const mutation = useMutation({
      mutationFn: async () => {
        return await client.refunProcess(id, status, not_refunded_note);
      },
      onSuccess: (data, variables, context) => {
        toast.success('Enregistré avec succès !', {position: 'bottom-center'})

        setTimeout(() => {
          navigate('/returned-products')
        }, 500);
      },
      onError: (data, error, variables, context) => {
        toast.error(data?.message , {position: 'bottom-center'})
      },
    });

    const getStatusLabel = (status) => {
    
      switch (status) {
  
        case 'pending':
          return 'En attente';
          break;
  
        case 'processing':
          return 'En traitement';
          break;
  
        case 'shipping':
          return 'En cours de livraison';
          break;
  
        case 'refund-request':
          return 'Processus de retour';
          break;
  
        case 'refunded':
          return 'Remboursé';
          break;
  
        case 'cancelled':
          return 'Annulé';
          break;
          
        case 'not-delivered':
          return 'Echec de livraison';
          break;
        case 'delivered':
          return 'Livré';
          break;
      
        default:
          break;
      }
    }


  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold"> #{currentData?._id} </h6>
            <button
                className="bg-gray-500 text-white active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  navigate('/orders')
                }}
              >
                <i class="fas fa-solid fa-arrow-left-long"></i>
              </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">

        <table className="items-center w-full bg-transparent border-collapse">
            
            <tbody>

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  N° Commande
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {currentData?._id}
                </td>
              </tr>

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                Sous total:
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {moneyFormating(currentData?.total_price ?? 0)}
                </td>
              </tr>

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                Frais de livraison:
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {moneyFormating(currentData?.shipping_cost ?? 0)}
                </td>
              </tr>

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                Montant total:
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {moneyFormating(currentData?.total_price ?? 0 + currentData?.shipping_cost ?? 0)}
                </td>
              </tr>

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                Statut
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {getStatusLabel(currentData?.status)}
                </td>
              </tr>

              <tr>
              <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">Methode de paiement:</th>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">Cash à la livraison</td>
              </tr>

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  Date de création
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {moment(currentData?.created_at).format('DD MMMM YYYY hh:m:s')}
                </td>
              </tr>

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  Livreur associé
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {currentData?.assigned_to?.full_name} / {currentData?.assigned_to?.phone_numbers[0]}
                </td>
              </tr>

              {currentData?.delivered_at && <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  Date de livraison
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {moment(currentData?.delivered_at).format('DD MMMM YYYY hh:m:s')}
                </td>
              </tr>}
              
            </tbody>
          </table>

          <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Panier
            </h6>

          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Produit</th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Total</th>
              </tr>
            </thead>
            <tbody>

              {currentData?.cart?.map((c, index) => {
                return <tr key={index} >
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                        <img width={100} className="rounded-lg mr-2" height={100} src={c?.images && c?.images[0]?.url} /> <p className='max-w-[600px] truncate text-ellipsis'>{c?.title}&nbsp;</p> <strong className="product-quantity">×&nbsp;{c?.qty}</strong>
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> <span className="Price-amount amount" >{moneyFormating(c?.promotion?.active ? c?.promotion?.cost : c?.price)}</span></td>
                      </tr>
                    })
                }
            </tbody>
          </table>

          <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Détails de la livraison
            </h6>

            <table className="items-center w-full bg-transparent border-collapse">
            
            <tbody>

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                 client
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {currentData?.user?.full_name} / {currentData?.user?.email_address} / {currentData?.user?.phone_numbers && currentData?.user?.phone_numbers[0]} {currentData?.shipping_address?.phone_number ? ` ; ${currentData?.shipping_address?.phone_number}` : ''}
                </td>
              </tr>

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                Adresse de livraison:
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {currentData?.shipping_address?.full_address}, {currentData?.shipping_address?.city} - Côte d'Ivoire
                </td>
              </tr>
            </tbody>
          </table>

          {currentData?.notes && <>
            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Note de la commande
            </h6>

            <textarea className="w-full rounded-lg resize-none focus:outline-none focus:shadow-none border-gray-500" readOnly>
              {currentData?.notes}
            </textarea>
          </>}

          {currentData?.not_refunded_note && <>
            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Motif du refus de retour
            </h6>

            <textarea className="w-full rounded-lg resize-none focus:outline-none focus:shadow-none border-gray-500" readOnly>
              {currentData?.not_refunded_note}
            </textarea>
          </>}

          <hr className="mt-6 border-b-1 border-blueGray-300" />
          <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Tracking
            </h6>
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Action</th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Date</th>
              </tr>
            </thead>
            <tbody>

              {currentData?.tracking?.map((c, index) => {
                return <tr key={index} >
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                        {c?.action}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> 
                        {moment(c?.date).format('DD MMMM YYYY hh:m:s')}
                        </td>
                      </tr>
                    })
                }
            </tbody>
          </table>

          {(currentData?.status !== "delivered" && currentData?.status !== "refunded") && <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Modifier les états
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Etat
                  </label>
                  <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  value={status}
                  onChange={(e) =>{
                    setStatus(e.target.value)
                  }}
                  >
                    <option value={"refund-request"}>Selectionner</option>
                    <option value={"accepted"}>Valider le retour</option>
                    <option value={"delivered"}>Refuser le retour</option>
                  </select>
                </div>
              </div>
            </div>

            {(currentData?.status !== "delivered" && status === 'delivered') && <>
              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
               Motif
              </h6>

              <textarea className="w-full rounded-lg resize-none focus:outline-none focus:shadow-none border-gray-500" 
              value={not_refunded_note ?? ''}
              onChange={(e) => {setnot_refunded_note(e.target.value)}}
              />
              </>}

          </form>}

          {(currentData?.status !== "delivered" && currentData?.status !== "refunded") && <div className="text-center flex justify-end mt-6">

            <button 
            disabled = {
              mutation?.isPending || status === 'refund-request'|| (status === 'delivered' && !not_refunded_note)
            }
            onClick={() => !mutation.isPending && mutation.mutate()}
            className="bg-[#001b97] text-white active:bg-[#001c97db] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                {mutation?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : 'Sauvegarder'}
            </button>
        </div>}

        </div>
      </div>
    </>
  );
}
