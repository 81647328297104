import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import BannerForm from "./BannerForm";
import BannerData from "./Banners";

// components

export default function Banners() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path={''} element={<BannerData />} />
        <Route path={'form/*'} element={<BannerForm />} />
        <Route path={'form/:id'} element={<BannerForm />} />
    </Routes>
  );
}