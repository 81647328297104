"use client";
import React, { useRef, useState } from "react";
import { useParams } from "react-router";
interface TablePaginationProps {
  data?: any;
  limit: number;
  currentPage: number;
  setCurrentPage: (item: number) => void;
}

function TablePagination({
  data,
  limit = 10,
  currentPage = 1,
  setCurrentPage,
}: TablePaginationProps) {


  const handlePageChange = (page: number) => {
    if (page > 0 && page <= (data ? Math.ceil(data.totalDocs / limit) : 0)) {
      setCurrentPage(page);
    }
  };

  const paginationItems = generatePaginationItems(
    currentPage,
    data ? Math.ceil(data.totalDocs / limit) : 0,
    handlePageChange
  );

  return (
    <>
    <div className="flex justify-end w-full my-3 gap-x-3 px-5 ">
              {/* Previous Button */}
      <a href="#" 
       onClick={(e: any) => {
        e.preventDefault()
        handlePageChange(currentPage - 1)
      }}
      className={`
        flex items-center justify-center px-3 h-8 text-sm font-medium text-indigo-500 bg-white border border-indigo-300 rounded-[5px] hover:bg-indigo-100 hover:text-indigo-700
        ${
          data?.prevPage ? "cursor-pointer" : "pointer-events-none cursor-not-allowed"
        }
        `}>
        <svg className="w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5H1m0 0 4 4M1 5l4-4" />
        </svg>
        Previous
      </a>
      <nav aria-label="Page navigation example">
        <ul className="flex items-center -space-x-px h-8 text-sm">
        {paginationItems}
        </ul>
      </nav>

      <a href="#" className={`
        flex items-center justify-center px-3 h-8 text-sm font-medium text-indigo-500 bg-white border border-indigo-300 rounded-[5px] hover:bg-indigo-100 hover:text-indigo-700
        ${
          data?.nextPage ? "cursor-pointer" : "pointer-events-none cursor-not-allowed"
        }
        `}
        onClick={(e: any) => {
          e.preventDefault()
          handlePageChange(currentPage + 1)
        }}
        >
        Next
        <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 5h12m0 0L9 1m4 4L9 9" />
        </svg>
      </a>
    </div>
    </>
  );
}

const renderItem = (i: number, page: number, handlePageChange: Function) => {
  return (
    
    <>
    <li>
      <a href="#" className={`
        ${page === i ? 'z-10  flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': 'flex items-center justify-center px-3 h-8 leading-tight text-indigo-500 bg-white border border-indigo-300 hover:bg-indigo-100 hover:text-indigo-700'}
      `}
      onClick={(e: any) => {
        e.preventDefault()
        handlePageChange(i)
      }}
      >

      {i}
      </a>
    </li>
    </>
  );
};

// Function to generate pagination items
const generatePaginationItems = (
  currentPage: number,
  totalPages: number,
  handlePageChange: Function
) => {
  const paginationItems = [];
  if (totalPages <= 5) {
    // Display 1 2 3 4 5
    for (let i = 1; i <= totalPages; i++) {
      paginationItems.push(renderItem(i, currentPage, handlePageChange));
    }
  } else {
    if (currentPage - 1 > 3) {
      // CurrentPage > 5 => Display 1 2 ... 5 6+
      paginationItems.push(renderItem(1, currentPage, handlePageChange));
      paginationItems.push(renderItem(2, currentPage, handlePageChange));
      paginationItems.push(
        <div key="ellipsis" className="pagination-item">
          ...
        </div>
      );
      paginationItems.push(
        renderItem(currentPage - 1, currentPage, handlePageChange)
      );
      paginationItems.push(
        renderItem(currentPage, currentPage, handlePageChange)
      );
    } else {
      // Current <= 4 Display 1 2 3 4 5 ...
      for (let i = 1; i <= currentPage; i++) {
        paginationItems.push(renderItem(i, currentPage, handlePageChange));
      }
    }
    if (currentPage + 1 < totalPages - 2) {
      // CurrentPage = 5 =>  6 ... 9 10
      paginationItems.push(
        renderItem(currentPage + 1, currentPage, handlePageChange)
      );
      paginationItems.push(
        <li>
        <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-indigo-500 bg-white border border-indigo-300 rounded-e-[5px] hover:bg-indigo-100 hover:text-indigo-700">
        ...
        </a>
      </li>
      );
      paginationItems.push(
        renderItem(totalPages - 1, currentPage, handlePageChange)
      );
      paginationItems.push(
        renderItem(totalPages, currentPage, handlePageChange)
      );
    } else {
      // CurrentPage = 5 =>  6 7 8 9 10
      for (let i = currentPage + 1; i <= totalPages; i++) {
        paginationItems.push(renderItem(i, currentPage, handlePageChange));
      }
    }
  }
  return paginationItems;
};

export default TablePagination;
