import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import ReturnData from "./Return";
import ReturnVieew from "./ReturnView";

// components

export default function Returns() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path={''} element={<ReturnData />} />
        <Route path={'view/:id'} element={<ReturnVieew />} />
    </Routes>
  );
}