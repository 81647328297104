import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import ShippingData from "./Shipping";
import ShippingVieew from "./ShippingView";

// components

export default function Shippings() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path={''} element={<ShippingData />} />
        <Route path={'view/:id'} element={<ShippingVieew />} />
    </Routes>
  );
}