import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import ProductForm from "./ProductForm";
import ProductData from "./Products";

// components

export default function Products() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path={''} element={<ProductData />} />
        <Route path={'form/*'} element={<ProductForm />} />
        <Route path={'form/:id'} element={<ProductForm />} />
    </Routes>
  );
}