import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import OrderData from "./Order";
import OrderView from "./OrderView";

// components

export default function Orders() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path={''} element={<OrderData />} />
        <Route path={'view/:id'} element={<OrderView />} />
    </Routes>
  );
}