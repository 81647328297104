import { Image } from "../../GlobalDataSchemas"

export enum POSITION {
  firts_bann_right = "firts_bann_right",
  first_bann_left = "first_bann_left",
  full_bann = "full_bann",
  stock_1 = "stock_1",
  stock_2 = "stock_2",
  stock_3 = "stock_3",
  stock_4 = "stock_4",
}
export interface CreateRequest {
  device_name: string
  order_id: string
  serial_number: string
  description: string
  images: Array<Image>
  full_name: string
  email_address: string
  phone_number: string
  is_new: boolean
  status: number
}

export interface Sva {
  _id: string
  device_name: string
  order_id: string
  serial_number: string
  description: string
  images: Array<Image>
  full_name: string
  email_address: string
  phone_number: string
  is_new: boolean
  status: number
  traited_by: any
  updated_by: string
}
