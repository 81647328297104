import { Image } from "../../GlobalDataSchemas"

export enum POSITION {
  firts_bann_right = "firts_bann_right",
  first_bann_left = "first_bann_left",
  full_bann = "full_bann",
  stock_1 = "stock_1",
  stock_2 = "stock_2",
  stock_3 = "stock_3",
  stock_4 = "stock_4",
}
export interface CreateRequest {
  email_address: string
  is_activated: boolean
}

export interface Newsletter {
  _id: string
  email_address: string
  is_activated: boolean
  created_at: Date
}
