import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import UserData from "./Users";
import UserForm from "./UserForm";

// components

export default function Users() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path={''} element={<UserData />} />
        <Route path={'form/*'} element={<UserForm />} />
        <Route path={'form/:id'} element={<UserForm />} />
    </Routes>
  );
}