import React, { useContext, useRef } from "react";
import useOnClickOutSide from "../../utils/utilities/onClickOutSide";
import { AuthContext } from "../../context/auth";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
const UserDropdown = () => {
  const navugate = useNavigate()
  const { sessionInfo, setUserInfo, signOut } = useContext(AuthContext)
  const ref = useRef(null)
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const openDropdownPopover = () => {

    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  useOnClickOutSide(ref, () => setDropdownPopoverShow(false))

  const logoutHandler = () => {
    mutation.mutate()
  };

  const mutation = useMutation({
    mutationFn: async () => {
      return await signOut();
      
    },
    onSuccess: (data, variables, context) => {
      toast("Tiste de vous voir déconnecté !", {
        duration: 4000,
        position: 'top-center',
      
        // Styling
        style: {},
        className: '',
      
        // Custom Icon
        icon: '🥺',
      
        // Change colors of success/error/loading icon
        iconTheme: {
          primary: '#000',
          secondary: '#fff',
        },
      
        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });

      setTimeout(() => {
        navugate('/login')
      }, 500);
    },
    onError: (data) => {
      toast.error(data?.message , {position: 'bottom-center'})
    },
  });
  return (
    <>
      <a
        className="text-blueGray-500 block relative"
        href="#pablo"
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-8 h-8 text-sm bg-white inline-flex items-center justify-center rounded-full">
            <i className="text-[23px] fas fa-solid fa-circle-user text-[#ed2939] "></i>
          </span>
        </div>

        <div
        ref={ref}
        className={
          (dropdownPopoverShow ? "block absolute top-11 right-0 " : "hidden ") +
          "bg-white text-base z-50 py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => {e.preventDefault(); logoutHandler()}}
        >
          Déconnexion
        </a>
      </div>
      </a>
      
    </>
  );
};

export default UserDropdown;
