import React from "react";

// components

import CardLineChart from "../../components/Cards/CardLineChart.js";
import CardBarChart from "../../components/Cards/CardBarChart.js";
import CardPageVisits from "../../components/Cards/CardPageVisits.js";
import CardSocialTraffic from "../../components/Cards/CardSocialTraffic.js";

export default function Dashboard() {
  return (
    <>
      
      <div className="flex flex-wrap mt-4">
        <div className="w-full px-4">
          <CardPageVisits />
        </div>
      </div>
    </>
  );
}
