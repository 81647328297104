// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'

import API from './config'
import AuthService from './services/auth'
import { Options } from '../../api/DataSchemas'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private authService!: AuthService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.authService = new AuthService(this.options)
  }


  authorize = (data: dataSchemas.AuthorizeRequest): Promise<dataSchemas.AuthorizeResponse> => this.authService.authorize(data)
  
  register = (data: dataSchemas.RegisterRequest): Promise<Record<string, any>> => this.authService.register(data)
  
  activateAccount = (data: dataSchemas.ActivateRequest): Promise<Record<string, any>> => this.authService.activateAccount(data)


  logout = () : Promise<string> => this.authService.logout()


  resetPasswordCode = (data: dataSchemas.ResetPasswordCodeRequest): Promise<Record<string, any>> => {
    return this.authService.resetPasswordCode(data)
  }

  resetPassword = (data: dataSchemas.PasswordResetCodeRequest): Promise<Record<string, any>> => {
    return this.authService.resetPassword(data)
  }


}

export * as types from './utils/DataSchemas'
