import { AuthContext, AuthStatus } from '../../context/auth';
import React, { useContext, useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export default function PassChange() {
  const {accessKey} = useParams()
  const {changePassword } = useContext(AuthContext)
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmpassword] = useState("");
  const [sucess, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('')

  const submit = async (e) => {
    e.preventDefault()
    setLoading(true)
    await changePassword({
      change_pass_token: accessKey,
      password,
      confirm_password
    })
      .then(() => {
        setLoading(false)
        setConfirmpassword('')
        setPassword('')
        setSuccess(true)
      })
      .catch((err) => {
          console.log('error', err)
          setError(err?.message)
          toast.error(err?.message , {position: 'bottom-center'})
          setLoading(false)
      })
  }
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  
                  {sucess ? <h6 className="text-blueGray-500 text-sm font-bold">Votre compte a été récupéré !</h6> : <h6 className="text-blueGray-500 text-sm font-bold">Enregistrer un nouveau mot de passe</h6>}
                </div>
                
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              {!sucess && <form autoComplete='off'>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Mot de passe
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                      placeholder='Mot de passe'
                      value={password}

                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Confirmer le mot de passe
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                      placeholder="Confirmer le mot de passe"
                      value={confirm_password}
                      onChange={(e) => setConfirmpassword(e.target.value)}
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      disabled={loading}
                      onClick={submit}
                    >
                      
                      {
                        loading ?
                          <>Loading...</>
                          :
                          <>
                           Se connecter
                          </>
                      }
                    </button>
                  </div>
                </form>} 
                
                {sucess ? <p>Votre compte a été récupéré avec succès. Veuillez <Link to="/login" className='font-bold text-[#001c96]'>vous connecter</Link> et comencer vos achats.</p>: error ? <p>Une erreur s'est produite ou votre lien a expriré. Veuillez réprendre le procéssus.</p> : ''}

                {error && <div className="w-full flex justify-center mt-3">
                        <div
                          className='w-full  p-2 text-center'
                          style={{
                            background: 'rgba(165, 23, 59, 0.1)',
                            borderRadius: 4,
                            fontWeight: 700,
                            fontSize: 16,
                            color: '#A5173B'
                          }}
                        >
                          {error}
                        </div>
                      </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
