import { useMutation, useQuery } from "@tanstack/react-query";
import { genererMotDePasse } from "../../../utils/utilities/constants";
import React, { useEffect, useState } from "react";

import useRoom from '../../../utils/utilities/hooks/useRoom';
// components
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router";
import { Riple, TrophySpin } from "react-loading-indicators";
import useImage from "../../../utils/utilities/hooks/useImage";
export default function RoomForm() {
    const navigate =  useNavigate()
    const [name, setName] = useState("");
    const [image, setImage] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const {id} = useParams()
    const [location, setLocation] = useState({
        full_address: 'Plateau Immeuble Media – rue du commerce',
        city: 'Abidjan'
    });
    const [phone_number, setPhonenumber] = useState("");
    const [loadImageUpload, setLoadImageUpload] = useState(false);
    let { client: imageClient } = useImage()
    let { client } = useRoom()

    useEffect(() => {
      if(currentData) {
        setEditMode(true)
        setName(currentData.name)
        setImage(currentData.image)
        setLocation(currentData.location)
        setPhonenumber(currentData?.phone_numbers[0])
      }
      
    }, [currentData])


    useQuery({
      queryKey: ['room-data', id],
      queryFn: async () => {
          if(id) {
            let result = await client.getRoomById(id)
            setCurrentData(result)
          }
      }
    })
    const mutation = useMutation({
      mutationFn: async () => {
        if(editMode && currentData) {
          return await client.updateRoom(currentData._id, {
            name,
            location,
            image,
            phone_numbers: [phone_number]
          });
        }else {
          return await client.createRoom({
            name,
            location,
            image,
            phone_numbers: [phone_number]
          });
        }
      },
      onSuccess: (data, variables, context) => {
        setName('')
        setImage(null)
        setLocation({
          full_address: 'Plateau Immeuble Media – rue du commerce',
          city: 'Abidjan'
        })
        setPhonenumber('')
        toast.success('Show room enregistré avec succès !', {position: 'bottom-center'})

        setTimeout(() => {
          navigate('/rooms')
        }, 500);
      },
      onError: (data, error, variables, context) => {
        toast.error(data?.message , {position: 'bottom-center'})
      },
    });

    const deleteItem = useMutation({
      mutationFn: async () => {
        return await client.deleteRoom(currentData._id);
      },
      onSuccess: (data, variables, context) => {
        toast.success( 'Show room  supprimé avec succès !', {position: 'bottom-center'})
        setTimeout(() => {
          navigate('/rooms')
        }, 500);
      },
      onError: (data, error, variables, context) => {
        toast.error(data?.message , {position: 'bottom-center'})
      },
    });

    const handleFileInputChange = async (e) => {
      const files = e.target.files;
      setLoadImageUpload(true)
      try {

        if(image) {
          await imageClient.deleteImage(image.public_id)
        }
        const result = await imageClient.uploadImage({image: files[0], folder: 'banners'})
        setImage(result);
      } catch (error) {

        toast.error('Veuillez réessayer avec une image de moins de 1mb' , {position: 'bottom-center'})
        
      }
      setLoadImageUpload(false)
    };
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold"> {!editMode ? 'Nouvelle agence' : `Modifier l'agence: ${currentData?.name}`} </h6>
            <button
                className="bg-gray-500 text-white active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  navigate('/rooms')
                }}
              >
                <i class="fas fa-solid fa-arrow-left-long"></i>
              </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informations basiques
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nom
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="Agence Plateau"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Contact
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="0102030405"
                    value={phone_number}
                    onChange={(e) => setPhonenumber(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Localisation
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Adresse complète
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="Plateau Immeuble Media – rue du commerce"
                    value={location.full_address}
                    onChange={(e) => setLocation({...location, full_address: e.target.value})}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    City
                  </label>
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="Abidjan"
                    value={location.city}
                    onChange={(e) => setLocation({...location, city: e.target.value})}
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informations complementaires
            </h6>
            <div className="flex flex-wrap">
            <div className="mt-2 flex items-center px-4">
                {image ? (<span className="inline-block mr-5 h-40 w-40 overflow-hidden">
                  
                    <img
                      src={image?.url}
                      alt="avatar"
                      className="h-full w-full object-cover"
                    />
                 
                </span> ) : (
                    <></>
                  )}

                <label htmlFor="uploadFile1" className="bg-white text-gray-500 p-3 font-semibold text-base rounded w-40 h-40 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 border-dashed mx-auto font-[sans-serif]">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-11 mb-2 fill-gray-500" viewBox="0 0 32 32">
                    <path d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z" data-original="#000000" />
                    <path d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z" data-original="#000000" />
                  </svg>
                  {loadImageUpload ? <TrophySpin color="#001b97" size="small" text="" textColor="" /> :  'Upload file'}
                  <input type="file" id="uploadFile1" className="hidden" 
                    onChange={handleFileInputChange}/>
                  <p className="text-xs font-medium text-gray-400 mt-2">PNG, JPG SVG, WEBP, and GIF are Allowed.</p>
                </label>

              </div>
            </div>
          </form>

          <div className="text-center flex justify-end mt-6">

          {editMode && <button 
            disabled={deleteItem?.isPending}
            onClick={() => !deleteItem.isPending && deleteItem.mutate()}
            className="bg-[#a80404] text-white active:bg-[#e24444d0] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                {deleteItem?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : 'Supprimer'}
            </button>}
            <button 
            disabled={mutation?.isPending
                ||
                (
                  name.trim() === "" ||
                  phone_number.trim() === "" ||
                  location?.full_address?.trim() === "" ||
                  location?.city?.trim() === ""
                )
            }
            onClick={() => !mutation.isPending && mutation.mutate()}
            className="bg-[#001b97] text-white active:bg-[#001c97db] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                {mutation?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : (editMode ? 'Sauvegarder' : 'Enregister')}
            </button>
        </div>

        </div>
      </div>
    </>
  );
}
