import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import SvaData from "./Svas";
import SvaView from "./SvaView";

// components

export default function Svas() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path={''} element={<SvaData />} />
        <Route path={'view/:id'} element={<SvaView />} />
    </Routes>
  );
}