import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";

import useCategory from '../../../utils/utilities/hooks/useCategory';
// components
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router";
import SupCategoryData from "./components/subcategoryData";
import SupCategory2Data from "./components/sub2categoryData";
import { Riple } from "react-loading-indicators";

export default function CategoryForm() {
    const navigate =  useNavigate()
    const [name, setName] = useState("");
    const [catl1_name, setCatl1name] = useState("");
    const [catl2_name, setCatl2name] = useState("");
    const [currentData, setCurrentData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [edit_cat_l1, setEditcatl1] = useState(false);
    const [edit_cat_l2, setEditcatl2] = useState(false);
    const [currentEditItemIndex, setCurrentEditItemIndex] = useState(-1);
    const [show_cat_l1_form, setShowcatl1form] = useState(false);
    const [show_cat_l2_form, setShowcatl2form] = useState(false);
    const [category_level_two, setCategoryleveltwo] = useState([]);
    const [category_level_three, setCategorylevelthree] = useState([]);
    
    const {id} = useParams()
    let { client } = useCategory()

    useEffect(() => {
      if(currentData) {
        setEditMode(true)
        setName(currentData.name)
        setCategoryleveltwo(currentData.category_level_two)
        setCategorylevelthree(currentData.category_level_three)
      }
      
    }, [currentData])


    useQuery({
      queryKey: ['category-data', id],
      queryFn: async () => {
          if(id) {
            let result = await client.getCategoryById(id)
            setCurrentData(result)
          }
      }
    })
    const mutation = useMutation({
      mutationFn: async () => {
        if(editMode && currentData) {
          return await client.updateCategory(currentData._id, {
            name,
            category_level_two,
            category_level_three
          });
        }else {
          return await client.createCategory({
            name,
            category_level_two,
            category_level_three
          });
        }
      },
      onSuccess: (data, variables, context) => {
        setName('')
        toast.success( 'Marque enregistrée avec succès !', {position: 'bottom-center'})

        setTimeout(() => {
          navigate('/categories')
        }, 500);
      },
      onError: (data, error, variables, context) => {
        toast.error(data?.message , {position: 'bottom-center'})
      },
    });

    const deleteItem = useMutation({
      mutationFn: async () => {
        return await client.deleteCategory(currentData._id);
      },
      onSuccess: (data, variables, context) => {
        toast.success( 'Marque supprimée avec succès !', {position: 'bottom-center'})
        setTimeout(() => {
          navigate('/categories')
        }, 500);
      },
      onError: (data, error, variables, context) => {
        toast.error(data?.message , {position: 'bottom-center'})
      },
    });

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold"> {!editMode ? 'Nouvelle catégorie' : `Modifier la catégorie`} </h6>
            <button
                className="bg-gray-500 text-white active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  navigate('/categories')
                }}
              >
                <i className="fas fa-solid fa-arrow-left-long"></i>
              </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informations basiques
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Libellé
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />
            
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="flex w-full items-center justify-between" >
                
                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                      Sous catégories
                    </h6>
                    <button
                      className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setShowcatl1form(true)
                        setShowcatl2form(false)
                      }}
                    >
                      <i className="fas fa-solid fa-plus"></i>
                    </button>
                </div>

                {show_cat_l1_form && !show_cat_l2_form && <div className="w-full mt-6">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Libellé
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                      value={catl1_name}
                      onChange={(e) => setCatl1name(e.target.value)}
                    />
                  </div>
                </div>}

                {show_cat_l1_form && !show_cat_l2_form && <div className="text-center flex justify-end mt-3 mb-6">
                  <button 
                  disabled={ catl1_name.trim() === ""}
                  onClick={() => {
                    if(!edit_cat_l1) {
                      setCategoryleveltwo([...category_level_two, {name: catl1_name}])
                      setCatl1name('');
                      setShowcatl1form(false)
                    }else {
                      category_level_two[currentEditItemIndex].name = catl1_name;
                      setCategoryleveltwo(category_level_two)
                      setCatl1name('');
                      setShowcatl1form(false)
                      setEditcatl1(false)
                    }
                  }}
                  className="bg-[#001b97] text-white active:bg-[#001c97db] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                      <i className="fas fa-solid fa-check"></i>
                  </button>
                </div>}

                <SupCategoryData
                categoryData={category_level_two}
                updateDataItems = {(data) => setCategoryleveltwo(data)}
                editItem = {
                  (item, index) => {
                    setEditcatl1(true)
                    setShowcatl1form(true)
                    setCatl1name(item.name)

                    setEditcatl2(false)
                    setShowcatl2form(false)
                    setCatl2name('')

                    setCurrentEditItemIndex(index)
                  }
                }
                />
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="flex w-full items-center justify-between" >
                
                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                      Catégories complémentaires
                    </h6>
                    <button
                      className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setShowcatl2form(true)
                        setShowcatl1form(false)
                      }}
                    >
                      <i className="fas fa-solid fa-plus"></i>
                    </button>
                </div>

                {show_cat_l2_form && !show_cat_l1_form && <div className="w-full mt-6">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Libellé
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                      value={catl2_name}
                      onChange={(e) => setCatl2name(e.target.value)}
                    />
                  </div>
                </div>}
                {show_cat_l2_form && !show_cat_l1_form && <div className="text-center flex justify-end mt-3 mb-6">
                  <button 
                    disabled={ catl2_name.trim() === ""}
                  onClick={() => {
                    if(!edit_cat_l2) {
                      setCategorylevelthree([...category_level_three, {name: catl2_name}])
                      setCatl2name('');
                      setShowcatl2form(false)
                    }else {
                      category_level_three[currentEditItemIndex].name = catl2_name;
                      setCategorylevelthree(category_level_three)
                      setCatl2name('');
                      setShowcatl2form(false)
                      setEditcatl2(false)
                    }

                  }}
                  className="bg-[#001b97] text-white active:bg-[#001c97db] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                      <i className="fas fa-solid fa-check"></i>
                  </button>
                </div>}

                <SupCategory2Data
                categoryData={category_level_three}
                updateDataItems = {(data) => setCategorylevelthree(data)}

                editItem = {
                  (item, index) => {
                    setEditcatl2(true)
                    setShowcatl2form(true)
                    setCatl2name(item.name)

                    setEditcatl1(false)
                    setShowcatl1form(false)
                    setCatl1name('')

                    setCurrentEditItemIndex(index)
                  }
                }
                />
              </div>
            </div>
          </form>

          <div className="text-center flex justify-end mt-6">
            {editMode && <button 
            disabled={deleteItem?.isPending}
            onClick={() => !deleteItem.isPending && deleteItem.mutate()}
            className="bg-[#a80404] text-white active:bg-[#e24444d0] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                {deleteItem?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : 'Supprimer'}
            </button>}

            <button 
            disabled={mutation?.isPending
                ||
                (
                  name.trim() === ""
                )
            }
            onClick={() => !mutation.isPending && mutation.mutate()}
            className="bg-[#001b97] text-white active:bg-[#001c97db] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                {mutation?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : (editMode ? 'Sauvegarder' : 'Enregister')}
            </button>
          </div>

        </div>
      </div>
    </>
  );
}
