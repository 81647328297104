import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux';
import RQProviders from './utils/providers/ReactQueryProvider';
import { store } from './utils/redux/store';
import App from './App';
import { Toaster } from 'react-hot-toast';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './index.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <RQProviders>
      <Router>
        <App />
        <Toaster />
      </Router>
    </RQProviders>
  </Provider>
);
