import { useMutation, useQuery } from "@tanstack/react-query";
import { genererMotDePasse } from "../../../utils/utilities/constants";
import React, { useContext, useEffect, useState } from "react";

import userUser from '../../../utils/utilities/hooks/userUser';
// components
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router";
import { AuthContext } from "../../../context/auth";
import { Riple } from "react-loading-indicators";
export default function CustomerDetails() {
    let { client } = userUser()
    const {id} = useParams()

    const [currentData, setCurrentData] = useState(null);
  
    useQuery({
      queryKey: ['user-data', id],
      queryFn: async () => {
          if(id) {
            let result = await client.getUserById(id)
            setCurrentData(result)
          }
      }
    })
    
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
        <div className="px-6">
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-4 flex justify-center">
              <div className="relative">
                <img
                  alt="..."
                  src={require("../../../assets/img/team-2-800x800.png")}
                  className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                />
              </div>
            </div>
            <div className="w-full px-4 text-center mt-20">
              <div className="flex justify-center py-4 lg:pt-4 pt-8">
                <div className="mr-4 p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                    22
                  </span>
                  <span className="text-sm text-blueGray-400">Friends</span>
                </div>
                <div className="mr-4 p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                    10
                  </span>
                  <span className="text-sm text-blueGray-400">Photos</span>
                </div>
                <div className="lg:mr-4 p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                    89
                  </span>
                  <span className="text-sm text-blueGray-400">Comments</span>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-12">
            <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
              {currentData?.full_name}
            </h3>
            <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
              <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>{" "}
              {currentData?.location?.city}, {currentData?.location?.full_address}
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}
