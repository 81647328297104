import { useMutation, useQuery } from "@tanstack/react-query";
import { genererMotDePasse } from "../../../utils/utilities/constants";
import React, { useContext, useEffect, useState } from "react";

import userUser from '../../../utils/utilities/hooks/userUser';
// components
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router";
import { AuthContext } from "../../../context/auth";
import { Riple } from "react-loading-indicators";
export default function UserForm() {
    const navigate =  useNavigate()
    const [first_name, setFirstname] = useState("");
    const [last_name, setLastname] = useState("");
    const [email_address, setEmailadress] = useState("");
    const [location, setLocation] = useState({
        full_address: 'Plateau Immeuble Media – rue du commerce',
        city: 'Abidjan'
    });
    const [password, setPassword] = useState("");
    const [phone_number, setPhonenumber] = useState("");
    const [role, _] = useState("admin");
    const [gender, setGender] = useState("man");
    const [action, setAction] = useState("manager");
    const [is_activated, setIsactivated] = useState(true);
    const [view_pass, setViewwpass] = useState(false);
    let { client } = userUser()
    const {id} = useParams()

    const [currentData, setCurrentData] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const { sessionInfo, setUserInfo } = useContext(AuthContext)
    useEffect(() => {
        setPassword(genererMotDePasse(12))
    }, [])

    useEffect(() => {
        if(currentData) {
            setEditMode(true)
            setFirstname(currentData.first_name)
            setLastname(currentData.last_name)
            setEmailadress(currentData.email_address)
            setLocation(currentData.location)
            setPassword()
            setPhonenumber(currentData.phone_numbers[0])
            setGender(currentData.gender)
            setAction(currentData.action)
            setIsactivated(currentData.is_activated)
        }
        
      }, [currentData])

  
    useQuery({
      queryKey: ['user-data', id],
      queryFn: async () => {
          if(id) {
            let result = await client.getUserById(id)
            setCurrentData(result)
          }
      }
    })
    const mutation = useMutation({
      mutationFn: async () => {

        if(editMode && currentData) {
          return await client.updateUser(currentData._id, {
            first_name,
            last_name,
            location,
            phone_numbers: [phone_number],
            role,
            gender,
            action,
            is_activated
          });
        }else {
          return await client.createUser({
            first_name,
            last_name,
            email_address,
            location,
            password,
            phone_numbers: [phone_number],
            role,
            gender,
            action,
            is_activated
          });
        }

        
      },
      onSuccess: (data, variables, context) => {
        setFirstname('')
        setLastname('')
        setEmailadress('')
        setLocation({
          full_address: 'Plateau Immeuble Media – rue du commerce',
          city: 'Abidjan'
        })
        setPassword('')
        setPhonenumber('')
        setGender('m')
        setAction('manager')
        setIsactivated(true)
        toast.success('Utilisateur enregistré avec succès!', {position: 'bottom-center'})

        setTimeout(() => {
          navigate('/users')
        }, 500);
      },
      onError: (data, error, variables, context) => {
        toast.error(data?.message , {position: 'bottom-center'})
      },
    });

    const deleteItem = useMutation({
      mutationFn: async () => {
        return await client.deleteUser(currentData._id);
      },
      onSuccess: (data, variables, context) => {
        toast.success( 'Utilisateur supprimé avec succès !', {position: 'bottom-center'})
        setTimeout(() => {
          navigate('/users')
        }, 500);
      },
      onError: (data, error, variables, context) => {
        toast.error(data?.message , {position: 'bottom-center'})
      },
    });
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold"> {!editMode ? 'Nouveau utilisateur' : `Modifier l'utilisateur: ${currentData?.full_name}`} </h6>
            <div className="relative w-full max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-gray-500 text-white active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  navigate('/users')
                }}
              >
                <i class="fas fa-solid fa-arrow-left-long"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informations basiques
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nom
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="Doe"
                    value={last_name}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Adresse email
                  </label>
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="johndoe@example.com"
                    value={email_address}
                    onChange={(e) => setEmailadress(e.target.value)}
                    disabled = {editMode}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Prénom(s)
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="John"
                    value={first_name}
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Contact
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="0102030405"
                    value={phone_number}
                    onChange={(e) => setPhonenumber(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Localisation
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Adresse complète
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="Plateau Immeuble Media – rue du commerce"
                    value={location.full_address}
                    onChange={(e) => setLocation({...location, full_address: e.target.value})}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    City
                  </label>
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="Abidjan"
                    value={location.city}
                    onChange={(e) => setLocation({...location, city: e.target.value})}
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informations d'accès
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Genre
                  </label>
                  <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  >
                    <option value={"man"}>Homme</option>
                    <option value={"woman"}>Femme</option>
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Rôle
                  </label>

                  <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  value={action}
                  onChange={(e) => setAction(e.target.value)}
                  >
                    <option value={"super"}>Super administrateur</option>
                    <option value={"manager"}>Manager</option>
                    <option value={"deliveryman"}>Livreur</option>
                    <option value={"product-checker"}>Gestionnaire de produit</option>
                    <option value={"order-checker"}>Gestionnaire de commande</option>
                    <option value={"retourn-manager"}>Gestionnaire de retour</option>
                    <option value={"chat-manager"} disabled>Gestionnaire de messagerie</option>
                    <option value={"sva-manager"}>Gestionnaire SAV</option>
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Statut
                  </label>
                  <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  value={is_activated ? 1 : 0}
                  onChange={(e) => setIsactivated(Number(e.target.value) === 1 ? true: false)}
                  >
                    <option value={1}>Actif</option>
                    <option value={0}>Inactif</option>
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Mot de passe {!view_pass ? <i onClick={() => setViewwpass(!view_pass)} className="ml-3 cursor-pointer fas fa-solid fa-eye"></i>: <i onClick={() => setViewwpass(!view_pass)} className="ml-3 cursor-pointer fas fa-solid fa-eye-slash"></i>}
                  </label>
                  <input
                    type={ view_pass ? "text" : "password"}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="*********"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled = {editMode}
                  />
                </div>
              </div>
            </div>
          </form>

          <div className="text-center flex justify-end mt-6">

          {editMode && (currentData?._id !== sessionInfo?.userInfo?._id ) && <button 
            disabled={deleteItem?.isPending}
            onClick={() => !deleteItem.isPending && deleteItem.mutate()}
            className="bg-[#a80404] text-white active:bg-[#e24444d0] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                {deleteItem?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : 'Supprimer'}
            </button>}
            <button 
            disabled={mutation?.isPending
                ||
                (
                  first_name.trim() === "" ||
                  last_name.trim() === "" ||
                  email_address.trim() === "" ||
                  phone_number.trim() === "" ||
                  gender.trim() === "" ||
                  action.trim() === "" ||
                  location?.full_address?.trim() === "" ||
                  location?.city?.trim() === "" ||
                  (!editMode && password === "")
                )
            }
            onClick={() => !mutation.isPending && mutation.mutate()}
            className="bg-[#001b97] text-white active:bg-[#001c97db] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                {mutation?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : (editMode ? 'Sauvegarder' : 'Enregister')}
            </button>
        </div>

        </div>
      </div>
    </>
  );
}
