import request from '../../../api'
import { Options } from '../../../api/DataSchemas'
import { PaginationOption } from '../../GlobalDataSchemas'
import { CreateRequest } from '../utils/DataSchemas'

function getPaginationQuery(paginationOption: PaginationOption) {
    let query = `?page=${paginationOption.page}&limit=${paginationOption.limit}`
    
    return query
  }

export default class Service {
    options: Options

    constructor(options: Options) {
        this.options = options
    }
    async createNewsletter(data: CreateRequest) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
    
      return request('POST', `/newsletter`, requestOptions)
    }

    async getNewsletters(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/newsletter${query}`, this.options)
    }

    async cancelNewsletter(email_address: string) {
      return request('PUT', `/newsletter/cancel/${email_address}`, this.options)
    }
}
