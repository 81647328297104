import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// components

import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import HeaderStats from "../components/Headers/HeaderStats.js";
import FooterAdmin from "../components/Footers/FooterAdmin.js";

// views

import Dashboard from "../views/admin/Dashboard.js";
import Maps from "../views/admin/Maps.js";
import Settings from "../views/admin/Settings.js";
import Tables from "../views/admin/Tables.js";
import Users from "../views/admin/users";
import Rooms from "../views/admin/rooms";
import Banners from "../views/admin/banners/index.js";
import Brands from "../views/admin/brands/index.js";
import Categories from "../views/admin/category/index.js";
import Products from "../views/admin/products/index.js";
import Customers from "../views/admin/customers/index.js";
import Newsletters from "../views/admin/newsletter/index.js";
import Svas from "../views/admin/sva/index.js";
import Orders from "../views/admin/orders/index.js";
import Shippings from "../views/admin/shippings/index.js";
import Returns from "../views/admin/returns/index.js";
import { AuthContext } from "../context/auth";

function View({path, actionsAllowed}) {
  const { sessionInfo, setUserInfo } = useContext(AuthContext)
  switch (sessionInfo.userInfo.action) {
    case 'super':
      return <Routes>
        <Route path={'/home'} element={<Dashboard />} />
        <Route path={'/users/*'} element={<Users />} />
        <Route path={'/customers/*'} element={<Customers />} />
        <Route path={'/rooms/*'} element={<Rooms />} />
        <Route path={'/banners/*'} element={<Banners />} />
        <Route path={'/brands/*'} element={<Brands />} />
        <Route path={'/categories/*'} element={<Categories />} />
        <Route path={'/products/*'} element={<Products />} />
        <Route path={'/newsletters/*'} element={<Newsletters />} />
        <Route path={'/customer-services/*'} element={<Svas />} />
        <Route path={'/orders/*'} element={<Orders />} />
        <Route path={'/deliveries/*'} element={<Shippings />} />
        <Route path={'/returned-products/*'} element={<Returns />} />
        
        <Route path={''} element={<Navigate replace to={'/home'} />} />
        <Route path={'*'} element={<Navigate replace to={'/home'} />} />
        <Route path={'/profile'} element={<Settings />} />
      </Routes>
      break;
    case 'manager':
      case 'super':
      return <Routes>
      <Route path={'/home'} element={<Dashboard />} />
      <Route path={'/users/*'} element={<Users />} />
      <Route path={'/customers/*'} element={<Customers />} />
      <Route path={'/rooms/*'} element={<Rooms />} />
      <Route path={'/banners/*'} element={<Banners />} />
      <Route path={'/brands/*'} element={<Brands />} />
      <Route path={'/categories/*'} element={<Categories />} />
      <Route path={'/products/*'} element={<Products />} />
      <Route path={'/newsletters/*'} element={<Newsletters />} />
      <Route path={'/customer-services/*'} element={<Svas />} />
      <Route path={'/orders/*'} element={<Orders />} />
      <Route path={'/deliveries/*'} element={<Shippings />} />
      <Route path={'/returned-products/*'} element={<Returns />} />
      
      <Route path={''} element={<Navigate replace to={'/home'} />} />
      <Route path={'*'} element={<Navigate replace to={'/home'} />} />
      <Route path={'/profile'} element={<Settings />} />
    </Routes>
      break;
    case 'deliveryman':
      return <Routes>
      <Route path={'/deliveries/*'} element={<Shippings />} />

      <Route path={''} element={<Navigate replace to={'/deliveries'} />} />
      <Route path={'*'} element={<Navigate replace to={'/deliveries'} />} />
      <Route path={'/profile'} element={<Settings />} />
    </Routes>
      break;
    case 'product-checker':
      return <Routes>
      <Route path={'/products/*'} element={<Products />} />
      <Route path={'/brands/*'} element={<Brands />} />
      <Route path={'/categories/*'} element={<Categories />} />
      <Route path={'/customer-services/*'} element={<Svas />} />
      <Route path={'/returned-products/*'} element={<Returns />} />

      <Route path={''} element={<Navigate replace to={'/products'} />} />
      <Route path={'*'} element={<Navigate replace to={'/products'} />} />
      <Route path={'/profile'} element={<Settings />} />
    </Routes>
      break;
    case 'order-checker':
      return <Routes>
      <Route path={'/orders/*'} element={<Orders />} />
        <Route path={'/deliveries/*'} element={<Shippings />} />
        <Route path={'/returned-products/*'} element={<Returns />} />

        <Route path={''} element={<Navigate replace to={'/orders'} />} />
        <Route path={'*'} element={<Navigate replace to={'/orders'} />} />
        <Route path={'/profile'} element={<Settings />} />
    </Routes>
      break;
    case 'retourn-manager':
      return <Routes>
      <Route path={'/returned-products/*'} element={<Returns />} />
     
      <Route path={''} element={<Navigate replace to={'/returned-products'} />} />
      <Route path={'*'} element={<Navigate replace to={'/returned-products'} />} />
      <Route path={'/profile'} element={<Settings />} />
    </Routes>
      break;
    case 'chat-manager':
      return <Routes>
      <Route path={''} element={<Dashboard />} />
      <Route path={'*'} element={<Navigate replace to={'/'} />} />
      <Route path={'/profile'} element={<Settings />} />
    </Routes>
      break;
    case 'sva-manager':
      return <Routes>
      <Route path={'/customer-services/*'} element={<Svas />} />
      
      <Route path={''} element={<Navigate replace to={'/customer-services'} />} />
      <Route path={'*'} element={<Navigate replace to={'/customer-services'} />} />
      <Route path={'/profile'} element={<Settings />} />
    </Routes>
      break;
    default:
      return null
      break;
  }
}

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="hide-scrollbar relative md:ml-64 min-h-screen bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <View/>
        </div>
      </div>
    </>
  );
}
