import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import useSva from '../../../utils/utilities/hooks/useSva';
// components
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router";
import { Riple, TrophySpin } from "react-loading-indicators";
export default function SvaView() {
    const navigate =  useNavigate()
    const [full_name, setFullName] = useState("");
    const [device_name, setDeviceName] = useState("");
    const [serial_number, setSerialNumber] = useState("");
    const [description, setDescription] = useState("");
    const [order_id, seOrderId] = useState("");
    const [email_address, setEmailadress] = useState("");
    const [phone_number, setPhonenumber] = useState("");
    const [status, setStatus] = useState(0);
    const [is_new, setIsNew] = useState(false);
    const [images, setImages] = useState([]);
    const [currentData, setCurrentData] = useState(null);

    const {id} = useParams()
    
    let { client } = useSva()

    useEffect(() => {
      if(currentData) {
        setFullName(currentData?.full_name)
        setDeviceName(currentData?.device_name)
        setSerialNumber(currentData?.serial_number)
        setDescription(currentData?.description)
        seOrderId(currentData?.order_id)
        setEmailadress(currentData?.email_address)
        setPhonenumber(currentData?.phone_number)
        setImages(currentData?.images)
        setStatus(currentData?.status)
        setIsNew(currentData?.is_new)
      }
      
    }, [currentData])


    useQuery({
      queryKey: ['sva-data', id],
      queryFn: async () => {
          if(id) {
            let result = await client.getSvaById(id)
            setCurrentData(result)
          }
      }
    })
    const mutation = useMutation({
      mutationFn: async () => {
        return await client.updateSva(currentData._id, {
          device_name,
          order_id,
          serial_number,
          description,
          images,
          full_name,
          email_address,
          phone_number,
          is_new,
          status
        });
      },
      onSuccess: (data, variables, context) => {
        toast.success('SVA enregistré avec succès !', {position: 'bottom-center'})

        setTimeout(() => {
          navigate('/customer-services')
        }, 500);
      },
      onError: (data, error, variables, context) => {
        toast.error(data?.message , {position: 'bottom-center'})
      },
    });

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold"> {device_name} </h6>
            <button
                className="bg-gray-500 text-white active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  navigate('/customer-services')
                }}
              >
                <i class="fas fa-solid fa-arrow-left-long"></i>
              </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">

        <table className="items-center w-full bg-transparent border-collapse">
            
            <tbody>

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  Nom de l'appareil
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {device_name}
                </td>
              </tr>

              {order_id && <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  N° Commande
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {order_id}
                </td>
              </tr>}

              {serial_number && <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  Numéro de série
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {serial_number}
                </td>
              </tr>}

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  Propriétaite
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {full_name} / {phone_number} / {email_address}
                </td>
              </tr>

              {currentData?.traited_by && <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  Traité par
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {currentData?.traited_by?.full_name} / {currentData?.traited_by?.email_address}
                </td>
              </tr>}

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  Etat
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {is_new ? 'Pris en compte' : 'Pas pris en compte'}
                </td>
              </tr>

              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  Statut
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {status === 0 ? 'En attente de traitement' : status === 1 ? 'Traité et livré': 'En cours de traitement'}
                </td>
              </tr>
              
            </tbody>
          </table>

          <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Détails du service
            </h6>

            <textarea className="w-full rounded-lg resize-none focus:outline-none focus:shadow-none border-gray-500" readOnly>
              {description}
            </textarea>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

          <div className="flex flex-wrap">
              <div className="mt-2 grid grid-cols-4 gap-5">

              {images &&
              images.map((i) => (
                <span className="relative inline-block h-40 w-40 overflow-hidden">
                  
                    <img
                      key={i?.url}
                      src={i?.url}
                      alt="avatar"
                      className="h-full w-full object-cover "
                    />
                    
                 
                </span>
              ))}

              </div>
            </div>

          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Modifier les états
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Prise en compte
                  </label>
                  <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  value={is_new ? 1 : 0}
                  onChange={(e) => setIsNew(Number(e.target.value) === 1 ? true: false)}
                  >
                    <option value={1}>Non</option>
                    <option value={0}>Oui</option>
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Status
                  </label>
                  <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={0}>En attente de traitement</option>
                    <option value={1}>Traité et livré</option>
                    <option value={2}>En cours de traitement</option>
                  </select>
                </div>
              </div>
            </div>

          </form>

          <div className="text-center flex justify-end mt-6">

            <button 
            
            onClick={() => !mutation.isPending && mutation.mutate()}
            className="bg-[#001b97] text-white active:bg-[#001c97db] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                {mutation?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : 'Sauvegarder'}
            </button>
        </div>

        </div>
      </div>
    </>
  );
}
