import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import RoomForm from "./RoomForm";
import RoomData from "./Rooms";

// components

export default function Rooms() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path={''} element={<RoomData />} />
        <Route path={'form/*'} element={<RoomForm />} />
        <Route path={'form/:id'} element={<RoomForm />} />
    </Routes>
  );
}