import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import CategoryForm from "./CategoryForm";
import CategoryData from "./Categories";

// components

export default function Categories() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path={''} element={<CategoryData />} />
        <Route path={'form/*'} element={<CategoryForm />} />
        <Route path={'form/:id'} element={<CategoryForm />} />
    </Routes>
  );
}