import { useNavigate } from "react-router";
import { AuthContext } from "../../context/auth";
import React, { useContext } from "react";

// components

export default function CardPageVisits() {
  const { sessionInfo, setUserInfo } = useContext(AuthContext)
  const navugate = useNavigate()
  const menuItems  = [
    
    {
      path: '/users',
      active: true,
      label: 'Utilisateurs',
      icon: 'fa-solid fa-people-group',
      actions: ['super', 'manager']
    },
    {
     path: '/rooms',
     active: true,
      label: 'Agences',
      icon: 'fa-solid fa-shop',
      actions: ['super', 'manager']
    },
    {
     path: '/banners',
     active: true,
      label: 'Bannières',
      icon: 'fa-solid fa-bandage',
      actions: ['super', 'manager']
    },
    {
     path: '/brands',
     active: true,
      label: 'Marques',
      icon: 'fa-solid fa-copyright',
      actions: ['super', 'manager', 'product-checker']
    },
    {
     path: '/categories',
     active: true,
      label: 'Catégories',
      icon: 'fa-solid fa-icons',
      actions: ['super', 'manager', 'product-checker']
    },
    {
      path: '/products',
      active: true,
      label: 'Produits',
      icon: 'fa-solid fa-camera',
      actions: ['super', 'manager', 'product-checker']
    },
    {
     path: '/customers',
     active: true,
     label: 'Clients',
     icon: 'fa-solid fa-people-carry-box',
     actions: ['super', 'manager']
   },
    {
     path: '/orders',
     active: true,
      label: 'Commandes',
      icon: 'fa-solid fa-tags',
      actions: ['super', 'manager', 'order-checker']
    },
    {
     path: '/deliveries',
     active: true,
      label: 'Livraison',
      icon: 'fa-solid fa-truck',
      actions: ['super', 'manager', 'deliveryman', 'order-checker']
    },
    {
      path: '/vouchers',
      active: false,
       label: 'Codes coupon',
       icon: 'fa-solid fa-ticket',
       actions: ['super', 'manager']
     },
     {
      path: '/newsletters',
      active: true,
       label: 'Newsletters',
       icon: 'fa-solid fa-envelopes-bulk',
       actions: ['super', 'manager']
     },
     {
      path: '/customer-services',
      active: true,
       label: 'Service après vente',
       icon: 'fa-solid fa-reply-all',
       actions: ['super', 'manager', 'product-checker', 'sva-manager']
     },
     {
      path: '/returned-products',
      active: true,
      label: 'Retours',
      icon: 'fa-solid fa-rotate-left',
      actions: ['super', 'manager', 'product-checker', 'order-checker', 'retourn-manager']
    },
    {
      path: '/blog',
      active: false,
      label: 'Blog',
      icon: 'fa-solid fa-newspaper',
      actions: ['super', 'manager']
    },
    {
     path: '/chat',
     active: false,
     label: 'Méssagerie',
     icon: 'fa-solid fa-comments-dollar',
     actions: ['super', 'manager']
   }
  ]
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Bienvenue {sessionInfo?.userInfo?.full_name}
              </h3>
            </div>
            
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
            <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Vos Modules
                </th>
                
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                  Action
                </th>
              </tr>
               
            </thead>
            <tbody>
              {menuItems.map((m, i) => {
                if(m.actions.includes(sessionInfo.userInfo.action))
                return <tr
                style={{
                  opacity: m.active ? 1 : .4,
                  pointerEvents: m.active ? 'initial' : 'none'
                }}
                >
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  {m.label}
                </th>
                
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                    <button
                      className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => navugate(m.path)}
                    >
                      Aller
                    </button>
                  </div>
                </td>
              </tr>  
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
