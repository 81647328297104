import React from "react";
import { Routes, Route, Redirect, Navigate } from "react-router-dom";

// components

import FooterSmall from "../components/Footers/FooterSmall.js";

// views

import Login from "../views/auth/Login.js";
import bg from "../assets//img/register_bg_2.png"
import PassForgot from "../views/auth/PassForgot.js";
import PassChange from "../views/auth/PassChange.js";
export default function Auth() {
  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage: `url(${bg})`,
            }}
          ></div>
          <Routes>
            <Route path={'/login'} element={<Login />} />
            <Route path={'/pass-forgot'} element={<PassForgot />} />
            <Route path={'/pass-change/:accessKey'} element={<PassChange />} />
            <Route path={''} element={<Navigate replace to={'/login'} />} />
            <Route path={'*'} element={<Navigate replace to={'/login'} />} />
          </Routes>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
