import request from '../../../api'
import { Options } from '../../../api/DataSchemas'
import { PaginationOption } from '../../GlobalDataSchemas'
import { CreateRequest, UpdateRequest } from '../utils/DataSchemas'

function getPaginationQuery(paginationOption: PaginationOption) {
  let query = `?page=${paginationOption.page}&limit=${paginationOption.limit}`

  if (typeof paginationOption.search !== "undefined") {
    query += `&search=${paginationOption.search}`
  }

  if (typeof paginationOption.role !== "undefined") {
    query += `&role=${paginationOption.role}`
  }
  return query
}

export default class UserService {
    options: Options

    constructor(options: Options) {
        this.options = options
    }
    async createUser(data: CreateRequest) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
    
      return request('POST', `/user/admin`, requestOptions)
    }

    async getUsers(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/user/all${query}`, this.options)
    }


    async getUserById(id: string) {
      return request('GET', `/user/profile/${id}`, this.options)
    }

    async deleteUser(id: string) {
    
        return request('DELETE', `/user/delete/${id}`, this.options)
    }

    async updateUser(id: string , data: UpdateRequest) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
    
      return request('PUT', `/user/update/${id}`, requestOptions)
    }
}
