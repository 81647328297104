import { useMutation, useQuery } from "@tanstack/react-query";
import { genererMotDePasse } from "../../../utils/utilities/constants";
import React, { useEffect, useState } from "react";

import useBrand from '../../../utils/utilities/hooks/useBrand';
// components
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router";
import { Riple } from "react-loading-indicators";
export default function BrandForm() {
    const navigate =  useNavigate()
    const [name, setName] = useState("");
    const [currentData, setCurrentData] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const {id} = useParams()
    let { client } = useBrand()

    useEffect(() => {
      if(currentData) {
        setEditMode(true)
        setName(currentData.name)
      }
      
    }, [currentData])


    useQuery({
      queryKey: ['brand-data', id],
      queryFn: async () => {
          if(id) {
            let result = await client.getBrandById(id)
            setCurrentData(result)
          }
      }
    })
    const mutation = useMutation({
      mutationFn: async () => {
        if(editMode && currentData) {
          return await client.updateBrand(currentData._id, {
            name
          });
        }else {
          return await client.createBrand({
            name
          });
        }
      },
      onSuccess: (data, variables, context) => {
        setName('')
        toast.success( 'Marque enregistrée avec succès !', {position: 'bottom-center'})

        setTimeout(() => {
          navigate('/brands')
        }, 500);
      },
      onError: (data, error, variables, context) => {
        toast.error(data?.message , {position: 'bottom-center'})
      },
    });

    const deleteItem = useMutation({
      mutationFn: async () => {
        return await client.deleteBrand(currentData._id);
      },
      onSuccess: (data, variables, context) => {
        toast.success( 'Marque supprimée avec succès !', {position: 'bottom-center'})
        setTimeout(() => {
          navigate('/brands')
        }, 500);
      },
      onError: (data, error, variables, context) => {
        toast.error(data?.message , {position: 'bottom-center'})
      },
    });

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold"> {!editMode ? 'Nouvelle marque' : `Modifier la marque`} </h6>
            <button
                className="bg-gray-500 text-white active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  navigate('/brands')
                }}
              >
                <i class="fas fa-solid fa-arrow-left-long"></i>
              </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informations basiques
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Libellé
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="hp"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </form>

          <div className="text-center flex justify-end mt-6">
            {editMode && <button 
            disabled={deleteItem?.isPending}
            onClick={() => !deleteItem.isPending && deleteItem.mutate()}
            className="bg-[#a80404] text-white active:bg-[#e24444d0] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                {deleteItem?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : 'Supprimer'}
            </button>}

            <button 
            disabled={mutation?.isPending
                ||
                (
                  name.trim() === ""
                )
            }
            onClick={() => !mutation.isPending && mutation.mutate()}
            className="bg-[#001b97] text-white active:bg-[#001c97db] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                {mutation?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : (editMode ? 'Sauvegarder' : 'Enregister')}
            </button>
        </div>

        </div>
      </div>
    </>
  );
}
