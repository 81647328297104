import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import NewsletterData from "./Newsletters";

// components

export default function Newsletters() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path={''} element={<NewsletterData />} />
    </Routes>
  );
}