import request from '../../../api'
import { Options } from '../../../api/DataSchemas'
import { ChangePasswordRequest, CreateRequest, UpdateMyProfileRequest, UpdatePicture, UpdateRequest } from '../utils/DataSchemas'
export default class ProfileService {
    options: Options

    constructor(options: Options) {
        this.options = options
    }
    async getMe() {
        return request('GET', `/user/me`, this.options)
    }

    async updateMyProfile(data: UpdateMyProfileRequest) {
        const requestOptions: Options = {
          ...this.options,
          data: data
        }
      
        return request('PUT', `/user/me`, requestOptions)
    }

   async changeMyPassword(data: ChangePasswordRequest) {
        const requestOptions: Options = {
        ...this.options,
        data: data
        }
    
            return request('POST', `/user/me/password`, requestOptions)
    }
}
