// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'

import API from './config'
import Service from './services'
import { Options } from '../../api/DataSchemas'
import { Pagination, PaginationOption } from '../GlobalDataSchemas'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private service!: Service

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.service = new Service(this.options)
  }
  createOrder = (
    body: dataSchemas.CreateRequest
  ): Promise<string> => this.service.createOrder(body)


  getAdminOrders = (
    paginationOption: PaginationOption = { page: 1, limit: 10}
  ): Promise<Pagination<dataSchemas.Order>> =>
  this.service.getAdminOrders({
    ...paginationOption
  })

  getAdminOrdersDeliveries= (
    paginationOption: PaginationOption = { page: 1, limit: 10}
  ): Promise<Pagination<dataSchemas.Order>> =>
  this.service.getAdminOrdersDeliveries({
    ...paginationOption
  })

  getReturns= (
    paginationOption: PaginationOption = { page: 1, limit: 10}
  ): Promise<Pagination<dataSchemas.Order>> =>
  this.service.getReturns({
    ...paginationOption
  })

  getUserOrders = (
    email_address: string,
    paginationOption: PaginationOption = { page: 1, limit: 10}
  ): Promise<Pagination<dataSchemas.Order>> =>
  this.service.getUserOrders(email_address, {
    ...paginationOption
  })

  getDeliverOrders = (
    id: string,
    paginationOption: PaginationOption = { page: 1, limit: 10}
  ): Promise<Pagination<dataSchemas.Order>> =>
  this.service.getDeliverOrders(id, {
    ...paginationOption
  })

  getOrderById = (
    id: string
  ): Promise<dataSchemas.Order> =>
  this.service.getOrderById(id)

  assignOrder = (
    id: string,
    assigned_to: any
  ): Promise<dataSchemas.Order> =>
  this.service.assignOrder(id, assigned_to)

  updateUserOrderStatus = (
    id: string,
    status: string
  ): Promise<dataSchemas.Order> =>
  this.service.updateUserOrderStatus(id, status)

  updateAdminOrderStatus = (
    id: string,
    status: string,
    failed_delivery_note: string
  ): Promise<dataSchemas.Order> =>
  this.service.updateAdminOrderStatus(id, status, failed_delivery_note)

  refunRequest = (
    id: string,
    status: string
  ): Promise<dataSchemas.Order> =>
  this.service.refunRequest(id, status)

  refunProcess = (
    id: string,
    status: string,
    not_refunded_note: string
  ): Promise<dataSchemas.Order> =>
  this.service.refunProcess(id, status, not_refunded_note)

}

export * as types from './utils/DataSchemas'
