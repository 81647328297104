import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import userUser from '../../../utils/utilities/hooks/userUser';
import TablePagination from "../../../components/TablePagination";
// components
let timer = null
export default function CustomerData() {
  const navigate = useNavigate()
  let { client } = userUser()

  const [page, setPage] = useState(1);
  const [limit, _] = useState(10);

  const [searchText, setSearchText] =
    useState("");
  const [value, setValue] =
    useState("");

  const {
    data: userData,
    isLoading
  } = useQuery({
    queryKey: ["users-data", page, limit, searchText],
    queryFn: async () => {
      let result = await client.getUsers({ page, limit, search: searchText, role: 'user' })
      return result;
    },
    keepPreviousData: true
  });

  const handleFilter = (e) => {
    setPage(1)
    setValue(e.target.value)
    if (timer) clearTimeout(timer)
    timer = setTimeout(async () => {
      try {
         setSearchText(e.target.value)
      } catch (error) {
        console.warn(error);
      }
    }, 1000)
  }
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Nos clients
              </h3>
            </div>

            <div className="relative w-full max-w-full flex-grow flex-1">
            <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-500 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="Rechercher..."
                    value={value}
                    onChange={handleFilter}
                  />
            </div>
            
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                     Nom complet
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Email
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Contact
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Adresse
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                    Actions
                </th>
              </tr>
            </thead>
            <tbody>

              {isLoading &&
                <tr>
                  <td colSpan={6}>
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        padding: 24,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      ><span style={{color: '#263238'}}>Chargement des données ... </span>
                        
                      </div>
                  </td>
                </tr>
              }

              
              {
                userData?.results?.length ?<>
                {userData?.results?.map((u, index) => {
                return <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                {u.gender === 'man' ?  <i className="fas fa-solid fa-child-reaching text-blue-500 mr-4"></i> : <i className="fas fa-solid fa-child-dress text-pink-500 mr-4"></i>} {u.full_name}
                </th>
                
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {u.email_address}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {u.phone_numbers[0]}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {u.location?.city}, {u.location?.full_address}
                </td>

                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="relative w-full max-w-full flex-grow flex-1 text-right">
                        <button
                        onClick={() => {
                          navigate(`/customers/view/${u._id}`)
                        }}
                            className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            <i className="fas fa-solid fa-arrows-to-eye"></i>
                        </button>
                    </div>
                </td>

                
              </tr>
              })}
                </> : <tr>
                <td colSpan={6}>
                  
                    <div className="font-bold" style={{padding: "15px 0px", display: 'flex', justifyContent: 'center', alignItems: "center", color: '#263238'}}>Aucune données</div>
                  </td></tr>
              }
              

              

            
              
              
            </tbody>
          </table>

          {userData?.results?.length ?  userData?.results && (
                <TablePagination
                  data={userData}
                  limit={limit}
                  currentPage={page}
                  setCurrentPage={setPage}
                />
              ): <></>}


        </div>
      </div>
    </>
  );
}