import React from "react";
import { createPopper } from "@popperjs/core";
import userPic from '../../assets/img/team-1-800x800.jpg'
const ChatIcon = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
        style={{
          opacity: .4,
          pointerEvents: 'none'
        }}
      >
        <div className="items-center flex">
          <span className="relative w-12 h-12 text-sm text-white inline-flex items-center justify-center rounded-full">
            <i className="text-[24px] fas fa-brands fa-rocketchat"></i>
            
            <span className="absolute font-bold p-1 text-[10px] top-0 right-0 w-5 h-5 bg-[#ed2939] text-white inline-flex items-center justify-center rounded-full">
              +9
            </span>
          </span>
        </div>
      </a>
    </>
  );
};

export default ChatIcon;
