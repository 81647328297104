import request from '../../../api'
import { Options } from '../../../api/DataSchemas'
import { PaginationOption } from '../../GlobalDataSchemas'
import { Image } from '../utils/DataSchemas'


export default class Service {
    options: Options

    constructor(options: Options) {
        this.options = options
    }
    async uploadImage(data: Image) {

      const formData = new FormData()
      formData.append('file', data.image)
  
      const requestOptions: Options = {
        ...this.options,
        headers: {
          'Content-Type': 'multipart/form-data',
          ...this.options.headers
        },
        data: formData
      }
    
      return request('POST', `/image/${data.folder}`, requestOptions)
    }

    async deleteImage(publicId: string) {
      const requestOptions: Options = {
        ...this.options,
        data: {publicId}
      }
      return request('DELETE', `/image`, requestOptions)
    }
}
