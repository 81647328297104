import request from '../../../api'
import { Options } from '../../../api/DataSchemas'
import { PaginationOption } from '../../GlobalDataSchemas'
import { CreateRequest, DescriptionImage, ReviewRequest } from '../utils/DataSchemas'

function getPaginationQuery(paginationOption: PaginationOption) {
    let query = `?page=${paginationOption.page}&limit=${paginationOption.limit}`
    if (typeof paginationOption.search !== "undefined") {
      query += `&search=${paginationOption.search}`
    }
    return query
  }

export default class Service {
    options: Options

    constructor(options: Options) {
        this.options = options
    }
    async createProduct(data: CreateRequest) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
    
      return request('POST', `/product`, requestOptions)
    }

    
    async getProducts(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/product/admin${query}`, this.options)
    }

    async getMostPromotedAndCheaperProducts(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/product/most-promoted-and-cheaper${query}`, this.options)
    }

    async getMostPopularProducts(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/product/most-popular${query}`, this.options)
    }

    async getMostNewArrivalsProducts(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/product/new-arrivals${query}`, this.options)
    }

    async getBigStockageProducts(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/product/big-stockage${query}`, this.options)
    }

    async getBestSellerProducts(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/product/best-seller${query}`, this.options)
    }

    async getProductsByCategory(paginationOption: PaginationOption, slug: string) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/product/category/${slug}${query}`, this.options)
    }

    async getProductsBySubCategory(paginationOption: PaginationOption, slug: string) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/product/category-level-three/${slug}${query}`, this.options)
    }

    async getProductsByComplementCategory(paginationOption: PaginationOption, slug: string) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/product/category-level-two/${slug}${query}`, this.options)
    }

    async getFullProducts(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/product/full${query}`, this.options)
    }

    async getProductById(id: string) {
      return request('GET', `/product/detailed/${id}`, this.options)
    }

    async viewProduct(id: string) {
      return request('PUT', `/product/view/${id}`, this.options)
    }

    async addToWish(id: string) {
      return request('PUT', `/product/wish/${id}`, this.options)
    }

    async reviewProduct(id: string, data: ReviewRequest) {

      const requestOptions: Options = {
        ...this.options,
        data: data
      }
      return request('PUT', `/product/review/${id}`, requestOptions)
    }


    async deleteProduct(id: string) {
    
        return request('DELETE', `/product/${id}`, this.options)
    }

    async updateProduct(id: string , data: CreateRequest) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
    
      return request('PUT', `/product/update/${id}`, requestOptions)
    }
}
