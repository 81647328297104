/*eslint-disable*/
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import NotificationDropdown from "../../components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "../../components/Dropdowns/UserDropdown.js";
import logo from '../../assets/img/logo-yepia-sntic.png'
import { AuthContext } from "../../context/auth";
export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const { sessionInfo, setUserInfo } = useContext(AuthContext)
  const menuItems  = [
    {
      header : 'Dashboard',
      active: true,
      menu: [
       {
         path: '/home',
         active: true,
         label: 'Accueil',
         icon: 'fa-solid fa-igloo',
         actions: ['super', 'manager']
       },
      //  {
      //    path: '/statistics',
      //    active: true,
      //    label: 'Statistiques',
      //    icon: 'fa-solid fa-chart-pie',
      //    actions: ['super', 'manager']
      //  },
      //  {
      //   path: '/accounting',
      //   active: true,
      //    label: 'Comptabilité',
      //    icon: 'fa-solid fa-money-bill-transfer',
      //    actions: ['super', 'manager']
      //  }
      ],
      actions: ['super', 'manager']
    },
    {
      header : 'Gestions',
      active: true,
      menu: [
       {
         path: '/users',
         active: true,
         label: 'Utilisateurs',
         icon: 'fa-solid fa-people-group',
         actions: ['super', 'manager']
       },
       {
        path: '/rooms',
        active: true,
         label: 'Agences',
         icon: 'fa-solid fa-shop',
         actions: ['super', 'manager']
       },
       {
        path: '/banners',
        active: true,
         label: 'Bannières',
         icon: 'fa-solid fa-bandage',
         actions: ['super', 'manager']
       },
       {
        path: '/brands',
        active: true,
         label: 'Marques',
         icon: 'fa-solid fa-copyright',
         actions: ['super', 'manager', 'product-checker']
       },
       {
        path: '/categories',
        active: true,
         label: 'Catégories',
         icon: 'fa-solid fa-icons',
         actions: ['super', 'manager', 'product-checker']
       }
      ],
      actions: ['super', 'manager', 'product-checker']
    },
    {
      header : 'Services',
      active: true,
      menu: [
       {
         path: '/products',
         active: true,
         label: 'Produits',
         icon: 'fa-solid fa-camera',
         actions: ['super', 'manager', 'product-checker']
       },
       {
        path: '/customers',
        active: true,
        label: 'Clients',
        icon: 'fa-solid fa-people-carry-box',
        actions: ['super', 'manager']
      },
       {
        path: '/orders',
        active: true,
         label: 'Commandes',
         icon: 'fa-solid fa-tags',
         actions: ['super', 'manager', 'order-checker']
       },
       {
        path: '/deliveries',
        active: true,
         label: 'Livraison',
         icon: 'fa-solid fa-truck',
         actions: ['super', 'manager', 'deliveryman', 'order-checker']
       }
      ],
      actions: ['super', 'manager', 'deliveryman', 'product-checker', 'order-checker']
    },
    {
      header : 'Annexes',
      active: true,
      menu: [
       {
        path: '/vouchers',
        active: false,
         label: 'Codes coupon',
         icon: 'fa-solid fa-ticket',
         actions: ['super', 'manager']
       },
       {
        path: '/newsletters',
        active: true,
         label: 'Newsletters',
         icon: 'fa-solid fa-envelopes-bulk',
         actions: ['super', 'manager']
       },
       {
        path: '/customer-services',
        active: true,
         label: 'Service après vente',
         icon: 'fa-solid fa-reply-all',
         actions: ['super', 'manager', 'product-checker', 'sva-manager']
       },
       {
        path: '/returned-products',
        active: true,
        label: 'Retours',
        icon: 'fa-solid fa-rotate-left',
        actions: ['super', 'manager', 'product-checker', 'order-checker', 'retourn-manager']
      },
      ],
      actions: ['super', 'manager', 'product-checker', 'order-checker', 'retourn-manager', 'sva-manager']
    },
    {
      header : 'Garder le contact',
      active: false,
      menu: [
       {
         path: '/blog',
         active: false,
         label: 'Blog',
         icon: 'fa-solid fa-newspaper',
         actions: ['super', 'manager']
       },
       {
        path: '/chat',
        active: false,
        label: 'Méssagerie',
        icon: 'fa-solid fa-comments-dollar',
        actions: ['super', 'manager']
      }
      ],
      actions: ['super', 'manager']
    }
  ]
  const { pathname } = useLocation()
  const getActiveClassname = (link) => {
    return pathname.startsWith(link);
  }
  return (
    <>
      <nav className="hide-scrollbar md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
      style={{
        boxShadow: '0 0 15px 0 rgba(34, 41, 47, .05)'
      }}
      >
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left  text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold "
            to="/"
          >
            <img src={logo} alt="" className="w-[150px] h-auto" />
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Notus React
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {
              menuItems.map((item, key) => {
                if(item.actions.includes(sessionInfo.userInfo.action))
                return <div key={key}
                style={{
                  opacity: item.active ? 1 : .4,
                  pointerEvents: item.active ? 'initial' : 'none'
                }}
                >
                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
                {/* Heading */}
                <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  {item.header}
                </h6>
                {/* Navigation */}

                

                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  {
                    item.menu.map((m, index) => {
                      if(m.actions.includes(sessionInfo.userInfo.action))
                      return <li 
                      style={{
                        opacity: m.active ? 1 : .4,
                        pointerEvents: m.active ? 'initial' : 'none'
                      }}
                      key={index} className="items-center">
                      <Link
                        className={
                          "text-xs uppercase py-3 block " +
                          (getActiveClassname(m.path)
                            ? "text-[#001b97] hover:text-[#001c97d1]"
                            : "text-[#625f6e] hover:text-[#625f6ec1]")
                        }
                        to={m.path}
                      >
                        <i
                          className={
                            `fas ${m.icon} mr-2 text-sm` +
                            (getActiveClassname(m.path)
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        {m.label}
                      </Link>
                    </li>
                    })
                  }
                </ul>
                    </div>
                  })
                }
          </div>
        </div>
        <hr className="my-2 border-b-1 border-blueGray-200" />
        <div className="text-sm text-blueGray-500 font-semibold py-1 text-center md:text-left">
                Copyright © {new Date().getFullYear()}{" "}
              </div>
      </nav>
    </>
  );
}
