import request from '../../../api'
import { Options } from '../../../api/DataSchemas'
import {
    AuthorizeRequest,
    ResetPasswordCodeRequest,
    PasswordResetCodeRequest,
    RegisterRequest,
    ActivateRequest
} from '../utils/DataSchemas'

export default class AuthService {
    options: Options

    constructor(options: Options) {
        this.options = options
    }

    async register(data: RegisterRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data,
        }
        return request('POST', `/user`, requestOptions)
    }

    async activateAccount(data: ActivateRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data,
        }
        return request('POST', `/user/activation`, requestOptions)
    }

    async authorize(data: AuthorizeRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data,
        }
        return request('POST', `/user/login`, requestOptions)
    }

    async logout() {
        return request('GET', `/user/logout`, this.options)
    }

    async resetPasswordCode(data: ResetPasswordCodeRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data,
        }
        return request('POST', `/user/reset-code`, requestOptions)
    }

    async resetPassword(data: PasswordResetCodeRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data,
        }
        return request('POST', `/user/change-password`, requestOptions)
    }

}
