
export default function SupCategory2Data({categoryData, updateDataItems, editItem}) {
 
  const deleteCat = (cat) => {
    const result = categoryData.filter(u => u.name !== cat.name)
    updateDataItems(result)
  }
  return (
    <div className="block w-full overflow-x-auto">
          {/* Projects table */}

          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Libellé
                </th>
                
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                    Actions
                </th>
              </tr>
            </thead>
            <tbody>
              
              {
                categoryData?.length ?<>
                {categoryData?.map((u, index) => {
                return <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  <div className="w-full flex items-center gap-3"  >
                    {u.name}
                  </div>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="relative w-full max-w-full flex-grow flex-1 text-right">
                        <button
                          onClick={() => {
                            deleteCat(u)
                          }}
                            className="bg-red-500 text-white active:bg-red-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                          <i className="fas fa-solid fa-trash"></i>
                        </button>

                        <button
                          onClick={() => {
                            editItem(u, index)
                          }}
                            className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            <i className="fas fa-solid fa-highlighter"></i>
                        </button>
                    </div>
                </td>

                
              </tr>
              })}
                </> : <tr>
                <td colSpan={6}>
                  
                    <div className="font-bold" style={{padding: "15px 0px", display: 'flex', justifyContent: 'center', alignItems: "center", color: '#263238'}}>Aucune données</div>
                  </td></tr>
              }
              
            </tbody>
          </table>

        </div>
  );
}