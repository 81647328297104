import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../../context/auth';
import { Client, types } from '../../../sdks/category-v1';
import { API_SERVER } from '../constants';

const client = new Client();

const useCategory = () => {
    const { signOut } = useContext(AuthContext);

    useEffect(() => {
        (async () => {

            client.configure({
                baseURL: API_SERVER,
                signOut
            })

        })()
    }, [])

    return { client, types }
}

export default useCategory;