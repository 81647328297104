import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Admin from './layouts/Admin';
import Auth from './layouts/Auth';
import NotProtectedRoute from './routes/NotProtectedRoute';
import AuthProvider, {
  AuthIsNotSignedIn,
  AuthIsSignedIn
} from './context/auth'
function App() {
  return (
    <AuthProvider>
        <AuthIsSignedIn>
          <Admin/>
        </AuthIsSignedIn>
        <AuthIsNotSignedIn>
          <Auth/>
        </AuthIsNotSignedIn>
    </AuthProvider>
  );
}

export default App;
