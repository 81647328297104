import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import CustomerData from "./Customers";
import CustomerDetails from "./CustomerDetails";

// components

export default function Customers() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path={''} element={<CustomerData />} />
        <Route path={'view/:id'} element={<CustomerDetails />} />
    </Routes>
  );
}