// Invoice.js
import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';

import logo from '../../../assets/img/logo-yepia-sntic.png'
import InvoiceTitle from './InvoiceTitle';
import InvoiceNo from './InvoiceNo';
import BillTo from './BillTo';
import InvoiceItemsTable from './InvoiceItemsTable';
import InvoiceThankYouMsg from './InvoiceThankYouMsg';
// Définir les styles pour le document PDF
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

// Composant pour la facture
const Invoice = ({ invoiceData }) => (
    <Document>
    <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={logo} />
        <InvoiceTitle title='Invoice'/>
        <InvoiceNo invoice={invoiceData}/>
        <BillTo invoice={invoiceData}/>
        <InvoiceItemsTable invoice={invoiceData} />
        <InvoiceThankYouMsg />
    </Page>
</Document>
);

export default Invoice;
