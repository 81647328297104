import React, { useState, useEffect, useContext } from 'react'
import useAuth from "../utils/utilities/hooks/useAuth";
import userUser from "../utils/utilities/hooks/userUser";
import { UserProfile } from '../sdks/user-v1/utils/DataSchemas';
import { AuthorizeResponse } from '../sdks/auth-v1/utils/DataSchemas';
import { AuthorizeRequest, PasswordResetCodeRequest, ResetPasswordCodeRequest } from '../sdks/auth-v1/utils/DataSchemas';
  export enum AuthStatus {
      Loading,
      SignedIn,
      SignedOut,
  }


  
  export interface IAuth {
    sessionInfo?: { accessToken?: string; refreshToken?: string, userInfo?: any}
    authStatus?: AuthStatus
    verifyCode?:any
    signIn?: any
    signOut?: any
    sendCode?: any
    forgotPassword?: any
    changePassword?: any
    setUserInfo?: any
    getRefreshToken?: any
  }
  
  const defaultState: IAuth = {
    sessionInfo: {},
    authStatus: AuthStatus.Loading
  }
  
  export const AuthContext = React.createContext<IAuth>(defaultState)

  export const AuthIsSignedIn: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { authStatus, sessionInfo }: IAuth = useContext(AuthContext)
    return <>{authStatus === AuthStatus.SignedIn &&
      (sessionInfo?.userInfo && sessionInfo?.userInfo?.role === 'admin') && children
    }</>
  }
  
  export const AuthIsNotSignedIn: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { authStatus }: IAuth = useContext(AuthContext)
  
    return <>{authStatus === AuthStatus.SignedOut && children}</>
  }

  const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    
    const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
    const [sessionInfo, setSessionInfo] = useState({})
    
    function setUserInfo(data: any) {
      setSessionInfo((oldSessionInfo) => {
        return {
          ...oldSessionInfo,
          userInfo: data
        }
      })
    }

    const data = useAuth()
    const authClient = data.client
    let { client } = userUser()
  
    useEffect(() => {
      async function getSessionInfo() {
        try {
  
          let user: UserProfile = await client.getMe()

          const simpleUser = {
            _id : user._id,
            first_name : user.first_name,
            last_name : user.last_name,
            full_name : user.full_name,
            email_address : user.email_address,
            location : user.location,
            phone_numbers : user.phone_numbers,
            role : user.role,
            gender : user.gender,
            action : user.action,
            avatar : user.avatar,
            is_activated: user.is_activated
          }
  
          setSessionInfo({
            userInfo: simpleUser
          });
  
          try {
            setAuthStatus(AuthStatus.SignedIn)
          } catch (e: any) {
            setAuthStatus(AuthStatus.SignedIn)
          }
        } catch (err: any) {
          setAuthStatus(AuthStatus.SignedOut)
        }
      }
  
      getSessionInfo()
    }, [authStatus])
  
    if (authStatus === AuthStatus.Loading) {
      return null
    }
  
    async function signIn({email_address, password}: AuthorizeRequest) {
      try {
        await authClient.authorize({
          email_address,
          password
        })
  
        setAuthStatus(AuthStatus.SignedIn)
      } catch (err) {
        console.log(err)
        setAuthStatus(AuthStatus.SignedOut)
        throw err
      }
    }
  
  
    async function signOut() {

      try {
        await authClient.logout()
        setSessionInfo({})
        setAuthStatus(AuthStatus.SignedOut)
        
      } catch (error) {
        console.error(error)
        throw error
      }
    }
  
  
    async function forgotPassword(data: ResetPasswordCodeRequest) {
      try {
        await authClient.resetPasswordCode(data)
        
      } catch (error) {
        console.error(error)
        throw error
      }
    }
  
    async function changePassword(data: PasswordResetCodeRequest) {
      try {
        await authClient.resetPassword(data)
        
      } catch (error) {
        console.error(error)
        throw error
      }
    }
  
    const authState: IAuth = {
      authStatus,
      sessionInfo,
      signIn,
      signOut,
      forgotPassword,
      changePassword,
      setUserInfo
      
    }
  
    return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  }
  
  export default AuthProvider