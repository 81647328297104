import request from '../../../api'
import { Options } from '../../../api/DataSchemas'
import { PaginationOption } from '../../GlobalDataSchemas'
import { CreateRequest } from '../utils/DataSchemas'

function getPaginationQuery(paginationOption: PaginationOption) {
    let query = `?page=${paginationOption.page}&limit=${paginationOption.limit}`
    
    return query
  }

export default class Service {
    options: Options

    constructor(options: Options) {
        this.options = options
    }
    async createRoom(data: CreateRequest) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
    
      return request('POST', `/room`, requestOptions)
    }

    async getRooms(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/room${query}`, this.options)
    }

    async getRoomById(id: string) {
      return request('GET', `/room/${id}`, this.options)
    }


    async deleteRoom(id: string) {
    
        return request('DELETE', `/room/${id}`, this.options)
    }

    async updateRoom(id: string , data: CreateRequest) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
    
      return request('PUT', `/room/update/${id}`, requestOptions)
    }
}
