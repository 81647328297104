import request from '../../../api'
import { Options } from '../../../api/DataSchemas'
import { PaginationOption } from '../../GlobalDataSchemas'
import { CreateRequest } from '../utils/DataSchemas'

function getPaginationQuery(paginationOption: PaginationOption) {
    let query = `?page=${paginationOption.page}&limit=${paginationOption.limit}`
    if (typeof paginationOption.search !== "undefined") {
      query += `&search=${paginationOption.search}`
    }
    return query
  }

export default class Service {
    options: Options

    constructor(options: Options) {
        this.options = options
    }
    async createBrand(data: CreateRequest) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
    
      return request('POST', `/brand`, requestOptions)
    }

    async getBrands(paginationOption: PaginationOption) {
      const query = getPaginationQuery(paginationOption)
      return request('GET', `/brand${query}`, this.options)
    }

    async getBrandById(id: string) {
      return request('GET', `/brand/${id}`, this.options)
    }

    async deleteBrand(id: string) {
    
        return request('DELETE', `/brand/${id}`, this.options)
    }

    async updateBrand(id: string , data: CreateRequest) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
    
      return request('PUT', `/brand/update/${id}`, requestOptions)
    }
}
